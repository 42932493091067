









import Vue from 'vue';
import AboutPageWhoWeAreContent from "@/views/About/AboutPageWhoWeAreContent.vue";

export default Vue.extend({
  name: "AboutPageWhoWeAre",
  components: {
    AboutPageWhoWeAreContent
  }
})
