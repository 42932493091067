<template>
  <v-container fluid class="about-who-we-are pa-0 pa-sm-3">
    <v-row
      no-gutters
      class="about-who-we-are__content_bg d-none d-sm-flex"
    >
      <v-col class="about-who-we-are__content_bg_left"></v-col>
      <v-col class="about-who-we-are__content_bg_right"></v-col>
    </v-row>
    <div class="about-who-we-are__content">
      <v-container class="container-wrapper px-0 pb-0 px-sm-3 pb-sm-3">
        <v-row>
          <v-col
              cols="12"
              sm="6"
              class="about-who-we-are__content_text"
          >
            <div>
              <h2>Who we are</h2>
              <p>Founded in 2014, ARI.company is a leading provider of marketing and software development services with more than 10 years of experience on the market and 15 skilled employees.</p>
            </div>
          </v-col>
          <v-col
              cols="12"
              sm="6"
              class="d-flex align-center justify-center pa-0"
          >
            <div class="about-who-we-are__content_count">
              <div class="about-who-we-are__counter">
                <span>10</span> years of expertise
              </div>
              <div class="about-who-we-are__counter">
                <span>15</span> skilled employees
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </v-container>
</template>

<script>
export default {
  name: "AboutPageWhoWeAreContent"
}
</script>

<style lang="scss" scoped>
.about-who-we-are {
  &__content {
    z-index: 1;
    display: flex;
    align-items: center;
  }
  &__content_bg {
    z-index: 0;
  }
  &__content_bg_left {
    background: #fff;
  }
  &__content_text {
    display: flex;
    align-items: center;
    padding: 48px 28px 48px 12px;
    text-align: center;

    p {
      margin-bottom: 0;
    }
  }
  &__content_count {
    display: flex;
    flex-wrap: wrap;
    background: linear-gradient(88.37deg, #39CAFF -2.45%, #855CDC 94.6%);
    padding: 40px 16px;
  }
  &__counter {
    flex-basis: 100%;
    max-width: 100%;

    color: #fff;
    font-size: 24px;
    font-weight: 500;
    line-height: 28px;

    display: flex;
    align-items: center;
    justify-content: center;

    span {
      font-weight: 700;
      font-size: 72px;
      line-height: 1;
      margin-right: 12px;
    }
    &:first-child {
      margin-bottom: 24px;
    }
  }

  @media (min-width: $mobile-large) {
    &__content,
    &__content_bg {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 230px;
    }
    &__content_bg_right {
      background: linear-gradient(88.37deg, #39caffc2 -2.45%, #855cdca6 94.6%);
    }
    &__content_text {
      text-align: left;
    }
    &__content_count {
      background: none;
      padding: 12px;
    }
  }

  @media (min-width: $desk-small) {
    &__content_count {
      flex-wrap: nowrap;
    }
    &__counter {
      flex-basis: 50%;
      max-width: 50%;
      justify-content: flex-start;

      span {
        font-weight: 700;
        font-size: 90px;
      }
      &:first-child {
        margin-right: 38px;
        margin-left: 24px;
        margin-bottom: 0;
      }
    }
  }

}
</style>