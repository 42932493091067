
























import Vue from 'vue';

export default Vue.extend({
  name: "AboutPageCeo",
  data () {
    return {
      content: 'Customer satisfaction is our main priority. I always communicate with our customers to ensure we provide an outstanding customer experience and the project realization complies with their expectations. I become very proud when I receive positive feedback from our customers about our technical competence, attention to detail, and precision. It\'s what inspires us to grow and develop.'
    }
  }
})
