






























import Vue from 'vue';

export default Vue.extend({
  name: "AboutPageMainDirections",
  data () {
    return {
      items: [
        {
          img: require('../../assets/icons/about/about-page-icon-crypto.svg'),
          title: 'Cryptocurrency',
          text: 'We build customizable and scalable cryptocurrency exchanges, wallets, etc.'
        },
        {
          img: require('../../assets/icons/about/about-page-icon-casino.svg'),
          title: 'Casino',
          text: 'We develop platforms for different types of casinos (online, private) and mobile applications.'
        },
        {
          img: require('../../assets/icons/about/about-page-icon-travel.svg'),
          title: 'Travel agencies',
          text: 'We provide a wide range of tailored-made solutions for travelling.'
        },
        {
          img: require('../../assets/icons/about/about-page-icon-bots.svg'),
          title: 'Bots',
          text: 'We develop virtual assistants for a variety of purposes, e.g., communication, gambling, cryptocurrency, etc.'
        },
      ]
    }
  }
})
