











import Vue from 'vue';
import AboutPageHero from "@/views/About/AboutPageHero.vue";
import AboutPageWhoWeAre from "@/views/About/AboutPageWhoWeAre.vue";
import AboutWeThink from "@/views/About/AboutWeThink.vue";
import AboutPageOurProjects from "@/views/About/AboutPageOurProjects.vue";
import AboutPageMainDirections from "@/views/About/AboutPageMainDirections.vue";
import AboutPageCeo from "@/views/About/AboutPageCeo.vue";

export default Vue.extend({
  name: "AboutPage",
  components: {
    AboutPageCeo,
    AboutPageMainDirections,
    AboutPageOurProjects,
    AboutWeThink,
    AboutPageWhoWeAre,
    AboutPageHero
  }
})
